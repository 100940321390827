import React, { useState } from 'react';
import Bazaar from '../assets/bazaar.png';

// Menu component for both mobile and desktop
const Menu = ({ className }) => (
  <nav className={className} aria-label="Main navigation">
    <ul className="text-zinc-500 md:flex md:gap-4 text-right md:text-zinc-600">
      <li className="mt-6 md:mt-0">
        <a href="https://web.listan.ir" className="block mt-4 md:mt-0 md:inline-block text-base min-w-32 py-2 outline-none border rounded-full text-center bg-[#72dcff] border-[#7bdeff] text-white hover:bg-[#64cdf0] hover:border-[#64cdf0]" >ورود به برنامه</a>
      </li>
      <li className="mt-6 md:mt-0">
        <a
          href="https://cafebazaar.ir/app/ir.listan.web.twa"
          className="min-w-32 block mt-4 md:mt-0 md:inline-block outline-none bg-black rounded-full flex justify-center items-center w-full h-[42px]"
          target="_blank" rel="noopener noreferrer"
          style={{
            backgroundImage: `url(${Bazaar})`,
            backgroundSize: '110px',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        >
        </a>
      </li>
      <li className="mt-6 md:mt-0">
        <a href="https://t.me/ListanSupport" className="block mt-4 md:mt-0 md:inline-block text-base min-w-32 py-2 outline-none border rounded-full text-center border-zinc-300 hover:bg-[#72dcff] hover:border-[#7bdeff] hover:text-white" >تماس با ما</a>
      </li>
    </ul>
  </nav>
);

// Logo component
const Logo = () => (
  <div className="flex items-center">
    <a className="flex items-center cursor-pointer" href="https://listan.ir">
      <img src="/logo512.png" className="h-14 ml-4" alt="Logo" />
      <div className="text-4xl leading-loose">
        <span className="text-[#1b528e]">لـ‌یـ‌سـ‌تــ‌</span>
        <span className="text-[#5ED2FA]">انــــــــــــــــــ‌</span>
      </div>
    </a>
  </div>
);

// Menu button component for mobile
const MenuButton = ({ toggleMenu }) => (
  <button onClick={toggleMenu} aria-label="Toggle Menu" className="block md:hidden items-center px-2 py-2 border rounded-full text-zinc-400 border-zinc-400 outline-none hover:ring-zinc-200 hover:ring-2 focus:ring-2 focus:ring-[#5ED2FA] focus:ring-opacity-50 focus:border-[#5ED2FA]">
    <svg className="fill-current h-6 w-6" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" >
      <path d="M4 6h16M4 12h16M4 18h16" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
    </svg>
  </button>
);

// Navbar component
const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => setIsOpen(!isOpen);

  return (
    <header className="pt-4 pb-4 pl-8 pr-8 relative z-10" role="banner" >
      <div className="flex justify-between items-center">
        <Logo />
        <MenuButton toggleMenu={toggleMenu} />
        <Menu className="hidden md:block md:items-center md:w-auto" />
      </div>
      <div className={`md:hidden transition-max-height duration-300 ease-in-out overflow-hidden ${isOpen ? 'max-h-screen' : 'max-h-0'}`}>
        <div className="m-5 border-t border-gray-300">
          <Menu />
        </div>
      </div>
    </header>
  );
};

export default Navbar;
