import React from "react";
import Hooray from "../assets/doingTask.avif";

const Intro = () => {
  return (
    <>
      <div className="container mx-auto text-zinc-600 flex flex-col md:flex-row justify-between items-center">
        <div className="md:w-6/12 order-2 p-8 md:order-1 flex flex-col gap-10">
          <h1 className="text-3xl font-semibold">
            مدیریت آسان کارها و یادداشت‌ها
          </h1>

          <p className="text-zinc-400 text-lg leading-8 text-justify">
            لیستان برنامه‌ای است که به شما امکان می‌دهد تا کارها و یادداشت‌های روزمره خود را به سرعت و سادگی ثبت و مدیریت کنید. با استفاده از تقویم جلالی و رابط کاربری ساده، لیستان به شما کمک می‌کند تا ایده‌ها، کارها و یادداشت‌های خود را در هر زمان و هر مکان سازماندهی کنید.
          </p>
          <a className="outline-none border w-full w-6/12 py-4 rounded-full text-lg font-semibold bg-[#72dcff] text-white hover:bg-[#64cdf0] text-center lg:w-6/12"
            href="https://web.listan.ir/register">
            همین حالا ثبت‌نام کن!
          </a>
        </div>

        {/* <!-- Image section --> */}
        <div className="order-1 md:order-2 mb-4 md:mb-0">
          <img src={Hooray} alt="خوشحالی بعد از موفقیت" className="w-full" />
        </div>
      </div>

    </>
  );
};

export default Intro;
